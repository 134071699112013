import React, { FC, useEffect, useMemo, useState } from "react";
import { LocationsWithDataQuery, SupplierOrderBy } from "@graphql/types";
import { useRouter } from "next/router";
import { CategoryTypes } from "@components/common/Category";
import { graphql } from "@helpers/graphql";
import { searchURL } from "@helpers/searchURL";

export interface SearchFilters {
  t: CategoryTypes;
  q?: string;
  city?: string;
  country?: string;
  category?: string;
  author?: string;
  duration?: string;
  language?: string;
  date?: string;
  adults?: number;
  children?: number;
  infants?: number;
  orderBy?: SupplierOrderBy;
  page: number;
}

export type IParamsProps = {
  hasSupplier: boolean;
  hasTour: boolean;
  hasVirtualTour: boolean;
  hasCar: boolean;
};

interface ContextProps {
  openFilter?: string;
  filters: SearchFilters;
  currentTabSelectedTitle: string;
  tabsWithContent: CategoryTypes[];
  setOpenFilter: (value: string | undefined) => void;
  updateTabsWithContent: (tabs: CategoryTypes[]) => void;
}

type ISanitizeResponse = {
  urlSanitized: string;
  needsRedirection: boolean;
  matchLocations: LocationsWithDataQuery | null;
  tabsAvaialble: CategoryTypes[];
};

export const sanitizeSearchUrl = async (
  url: string
): Promise<ISanitizeResponse> => {
  const previousParamsString = url.split("?")[1];
  const previousParams = new URLSearchParams(previousParamsString);
  const previousQ = previousParams.get("q")?.toLowerCase();
  const newParams = new URLSearchParams(previousParamsString);

  // if the keyword doesn’t match any city name or country name AND it’s 3 or more characters,
  // go to the q={keyword} page, but also return the destination list on top of the search,
  // for countries and cities that matched the {keyword}
  let locations: LocationsWithDataQuery | undefined;

  if (!!previousQ && previousQ.length >= 3) {
    locations = await graphql.locationsWithData({
      limit: 8,
      filters: {
        MATCH: previousQ,
      },
    });

    // Check if there is any exact match for the locations, redirect to that pages.
    locations?.locations?.forEach((element) => {
      if (
        element.name.toLowerCase() === previousQ ||
        element.slug.toLowerCase() === previousQ
      ) {
        if (element.__typename === "City") {
          newParams.set("city", element.slug);
          newParams.set("t", "guides"); // default t from the input search is tours
          newParams.delete("q");
        }

        if (element.__typename === "Country") {
          newParams.set("country", element.slug);
          newParams.set("t", "guides"); // default t from the input search is tours
          newParams.delete("q");
        }
      }
    });
  }

  // Fetch available tabs data based on the current query (e.g., country, city, keyword)
  const tabData = await graphql.SearchPageTabsData({
    filters: {
      ...(newParams.get("country") && {
        COUNTRY_SLUG: newParams.get("country"),
      }),
      ...(newParams.get("city") && { CITY_SLUG: newParams.get("city") }),
      ...(newParams.get("q") && { SEARCH: newParams.get("q") }),
    },
  });

  // Handle deprecated 'online-experiences' query parameter
  if (newParams.get("t") === "online-experiences") {
    newParams.set("t", CategoryTypes["online-tours"]);
  }
  // Determine the default tab
  // If searching by country or city, prioritize the 'guides' tab if available; otherwise, use the first valid tab
  // Redirect to the default tab if 't' is not specified in the query
  if (!newParams.get("t")) {
    const tAvailable = findTabAvailable({
      payload: tabData.searchPageTabsData,
      defaultTab:
        newParams.get("country") || newParams.get("city")
          ? CategoryTypes.guides
          : CategoryTypes.tours,
    });
    newParams.set("t", tAvailable);
  }

  //This ensures the URL doesn't include page=1, as it's unnecessary for SEO purposes.
  if (newParams.get("page") === "1") {
    newParams.delete("page");
  }

  const tabsAvailable = tabsWithContentList(tabData.searchPageTabsData);
  // If the determined tab differs from the requested tab, redirect to the correct tab
  if (!tabsAvailable.includes(newParams.get("t") as CategoryTypes)) {
    const tAvailable = findTabAvailable({
      payload: tabData.searchPageTabsData,
      defaultTab:
        newParams.get("country") || newParams.get("city")
          ? CategoryTypes.guides
          : CategoryTypes.tours,
    });
    newParams.set("t", tAvailable);
  }

  const newParamsObject = Object.fromEntries(newParams);

  const urlSanitized = searchURL(newParamsObject);
  const sanitedParamsString = urlSanitized.split("?")[1];

  return {
    urlSanitized: urlSanitized.toLowerCase(), // /s?xx=yy&&zz=ww...
    needsRedirection:
      previousParams.toString().toLowerCase() !=
      sanitedParamsString.toLowerCase(),
    matchLocations: locations || null, // SSR call cant receive undefined
    tabsAvaialble: tabsAvailable,
  };
};

export const findTabAvailable = ({
  payload,
  defaultTab,
}: {
  payload?: IParamsProps;
  defaultTab: CategoryTypes;
}): CategoryTypes => {
  if (defaultTab === "guides") {
    if (payload?.hasSupplier) {
      return CategoryTypes.guides;
    }
    if (payload?.hasTour) {
      return CategoryTypes.tours;
    }
    if (payload?.hasCar) {
      return CategoryTypes.transportation;
    }
    if (payload?.hasVirtualTour) {
      return CategoryTypes["online-tours"];
    }
  } else {
    if (payload?.hasTour) {
      return CategoryTypes.tours;
    }
    if (payload?.hasCar) {
      return CategoryTypes.transportation;
    }
    if (payload?.hasVirtualTour) {
      return CategoryTypes["online-tours"];
    }
    if (payload?.hasSupplier) {
      return CategoryTypes.guides;
    }
  }

  return defaultTab;
};

export const tabsWithContentList = (payload?: IParamsProps) => {
  const tabs = [] as CategoryTypes[];
  if (payload?.hasSupplier) {
    tabs.push(CategoryTypes.guides);
  }
  if (payload?.hasTour) {
    tabs.push(CategoryTypes.tours);
  }
  if (payload?.hasCar) {
    tabs.push(CategoryTypes.transportation);
  }
  if (payload?.hasVirtualTour) {
    tabs.push(CategoryTypes["online-tours"]);
  }
  return tabs;
};

const getSearchParams = (): SearchFilters => {
  if (typeof window === "undefined") {
    return { t: CategoryTypes.guides, page: 1 };
  }

  const searchParams = new URLSearchParams(window.location.search);

  // Initialize params with all existing URL parameters
  const params: SearchFilters = {
    t: CategoryTypes.guides,
    page: 1,
  };

  // Populate params from URL search parameters
  searchParams.forEach((value, key) => {
    if (key === "t") {
      params.t = value as CategoryTypes;
    } else if (key === "page") {
      params.page = parseInt(value, 10) || 1;
    } else {
      // Keep all other parameters
      (params as any)[key] = value;
    }
  });

  const searchParamsObject = Object.fromEntries(searchParams);

  return {
    ...searchParamsObject,
    t: searchParamsObject.t as CategoryTypes,
    page: parseInt(searchParamsObject.page, 10) || 1,
  };
};

export const SearchContext = React.createContext<ContextProps>({
  setOpenFilter: () => null,
  filters: getSearchParams(),
  currentTabSelectedTitle: "",
  tabsWithContent: [],
  updateTabsWithContent: () => null,
});

export const SearchProvider: FC<{ filters?: SearchFilters }> = ({
  ...props
}) => {
  const router = useRouter();
  const [openFilter, setOpenFilter] = useState<string | undefined>();

  const [filters, setFilters] = useState<SearchFilters>(getSearchParams());
  const [tabsWithContent, setTabsWithContent] = useState<CategoryTypes[]>([]);

  const currentTabSelectedTitle = useMemo(() => {
    if (filters.t === "tours") {
      return "Private Tours";
    } else if (filters.t === "guides") {
      return "Local Guides";
    } else if (filters.t === "online-tours") {
      return "Virtual Tours";
    } else if (filters.t === "transportation") {
      return "Private Car Tours";
    } else {
      return "Private Tours";
    }
  }, [filters.t]);

  useEffect(() => {
    const filtersUpdated = getSearchParams();
    setFilters({ ...filtersUpdated });
  }, [router.asPath]);

  const updateTabsWithContent = (tabs: CategoryTypes[]) => {
    setTabsWithContent(tabs);
  };

  return (
    <SearchContext.Provider
      value={{
        filters,
        openFilter,
        currentTabSelectedTitle,
        tabsWithContent,
        setOpenFilter,
        updateTabsWithContent,
      }}
      {...props}
    />
  );
};

export const useSearch = () => {
  const state = React.useContext(SearchContext);

  return {
    ...state,
  };
};
