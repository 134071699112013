import React, { FC } from "react";
import { IconProps } from "../types";

export const LostCharacter: FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="512"
      height="512"
      preserveAspectRatio="xMidYMid meet"
      style={{
        width: "100%",
        height: "100%",
        transform: "translate3d(0px, 0px, 0px)",
      }}
    >
      <defs>
        <clipPath id="__lottie_element_716">
          <rect width="512" height="512" x="0" y="0"></rect>
        </clipPath>
        <clipPath id="__lottie_element_718">
          <path d="M0,0 L1080,0 L1080,1080 L0,1080z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#__lottie_element_716)">
        <g
          clipPath="url(#__lottie_element_718)"
          transform="matrix(0.44999998807907104,0,0,0.44999998807907104,13,13)"
          opacity="1"
          style={{
            display: "block",
          }}
        >
          <g
            transform="matrix(-0.9538927674293518,0.028216006234288216,0.028216006234288216,0.9538927674293518,918.9742431640625,227.86306762695312)"
            opacity="1"
            style={{
              display: "none",
            }}
          >
            <g opacity="1" transform="matrix(1,0,0,1,246.5,185.5)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M113.5,125.5 C113.5,125.5 157.5,86.5 138.5,82.5 C119.5,78.5 96.5,100.5 110.5,78.5 C124.5,56.5 168.5,30.5 125.5,38.5 C90.56300354003906,45 13.375,48.19900131225586 11.9399995803833,39.51599884033203 C11.609000205993652,37.512001037597656 15.312000274658203,34.875 24.5,31.5 C73.5,13.5 -0.7960000038146973,128.11900329589844 -31.79599952697754,122.11900329589844 C-62.79600143432617,116.11900329589844 -85.53399658203125,-14.208999633789062 -32.534000396728516,-11.208999633789062 C20.465999603271484,-8.208999633789062 40.5,-27.5 11.5,-5.5 C-17.5,16.5 -77.5,26.5 -43.5,41.5 C-9.5,56.5 8.5,92.5 24.5,68.5 C40.5,44.5 52.94200134277344,92.62999725341797 78.94200134277344,87.62999725341797 C104.94200134277344,82.62999725341797 94.5,-12.5 68.5,-13.5 C42.5,-14.5 12.5,29.5 -2.5,28.5 C-17.5,27.5 -75.41000366210938,14.324999809265137 -105.41000366210938,20.325000762939453 C-135.41000366210938,26.325000762939453 -97.5,2.5 -82.5,31.5 C-67.5,60.5 -7.519999980926514,70.71900177001953 11.479999542236328,62.71900177001953 C11.479999542236328,62.71900177001953 96.92900085449219,55.26100158691406 63.92900085449219,45.26100158691406 C30.929000854492188,35.26100158691406 -108.5,46.5 -101.5,60.5 C-94.5,74.5 110.5,-9.5 65.5,-34.5 C20.5,-59.5 139.73500061035156,47.145999908447266 164.73500061035156,26.145999908447266 C189.73500061035156,5.145999908447266 133.5,-68.5 121.5,-35.5 C109.5,-2.5 -1.843000054359436,66.25700378417969 -8.843000411987305,34.25699996948242 C-15.843000411987305,2.256999969482422 -149.11000061035156,48.5 -106.11000061035156,79.5 C-63.11000061035156,110.5 -90.36900329589844,111.28399658203125 -126.36900329589844,118.28399658203125 C-162.36900329589844,125.28399658203125 -150.17599487304688,106.42500305175781 -111.1760025024414,92.42500305175781 C-72.1760025024414,78.42500305175781 89.5,20.5 98.5,4.5"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(1,0,0,1,-184,47.24798583984375)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g opacity="1" transform="matrix(1,0,0,1,694,825)">
              <path
                fill="rgb(253,199,201)"
                fillOpacity="1"
                d=" M-13.649999618530273,-126 C-13.649999618530273,-126 -13.649999618530273,87.2300033569336 -13.649999618530273,87.2300033569336 C-13.649999618530273,87.2300033569336 -64,87.89299774169922 -64,126 C-64,126 64,126 64,126 C64,126 64,-126 64,-126"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(1,0,0,1,-184,47.24798583984375)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g opacity="1" transform="matrix(1,0,0,1,676,848.5)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M-13.649999618530273,-102.5 C-13.649999618530273,-102.5 -13.649999618530273,70.96199798583984 -13.649999618530273,70.96199798583984 C-13.649999618530273,70.96199798583984 -64,71.5 -64,102.5 C-64,102.5 64,102.5 64,102.5 C64,102.5 64,-102.5 64,-102.5"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(1,0,0,1,-184,47.24798583984375)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g opacity="1" transform="matrix(1,0,0,1,863,833.5)">
              <path
                fill="rgb(253,199,201)"
                fillOpacity="1"
                d=" M-13.649999618530273,-117.5 C-13.649999618530273,-117.5 -13.649999618530273,81.34600067138672 -13.649999618530273,81.34600067138672 C-13.649999618530273,81.34600067138672 -64,81.96399688720703 -64,117.5 C-64,117.5 64,117.5 64,117.5 C64,117.5 64,-117.5 64,-117.5"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(1,0,0,1,-184,47.24798583984375)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g opacity="1" transform="matrix(1,0,0,1,843,848.5)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M-13.649999618530273,-102.5 C-13.649999618530273,-102.5 -13.649999618530273,70.96199798583984 -13.649999618530273,70.96199798583984 C-13.649999618530273,70.96199798583984 -64,71.5 -64,102.5 C-64,102.5 64,102.5 64,102.5 C64,102.5 64,-102.5 64,-102.5"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(0.9999179244041443,-0.012811299413442612,0.012811299413442612,0.9999179244041443,-193.064208984375,59.5469970703125)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g opacity="1" transform="matrix(1,0,0,1,805,516)">
              <path
                fill="rgb(253,199,201)"
                fillOpacity="1"
                d=" M144.04800415039062,230 C144.04800415039062,230 -144.04800415039062,230 -144.04800415039062,230 C-193.7259979248047,230 -234,189.7270050048828 -234,140.04800415039062 C-234,140.04800415039062 -234,-140.04800415039062 -234,-140.04800415039062 C-234,-189.7270050048828 -193.7259979248047,-230 -144.04800415039062,-230 C-144.04800415039062,-230 144.04800415039062,-230 144.04800415039062,-230 C193.7270050048828,-230 234,-189.7270050048828 234,-140.04800415039062 C234,-140.04800415039062 234,140.04800415039062 234,140.04800415039062 C234,189.7270050048828 193.7270050048828,230 144.04800415039062,230z"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(0.9999179244041443,-0.012811299413442612,0.012811299413442612,0.9999179244041443,-193.064208984375,59.5469970703125)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g opacity="1" transform="matrix(1,0,0,1,781,512)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M138.04800415039062,230 C138.04800415039062,230 -138.04800415039062,230 -138.04800415039062,230 C-187.7259979248047,230 -228,189.7270050048828 -228,140.04800415039062 C-228,140.04800415039062 -228,-140.04800415039062 -228,-140.04800415039062 C-228,-189.7270050048828 -187.7259979248047,-230 -138.04800415039062,-230 C-138.04800415039062,-230 138.04800415039062,-230 138.04800415039062,-230 C187.7270050048828,-230 228,-189.7270050048828 228,-140.04800415039062 C228,-140.04800415039062 228,140.04800415039062 228,140.04800415039062 C228,189.7270050048828 187.7270050048828,230 138.04800415039062,230z"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(0.9999179244041443,-0.012811299413442612,0.012811299413442612,0.9999179244041443,-9.68463134765625,9.94561767578125)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g
              opacity="1"
              transform="matrix(1,0,0,1,322.3110046386719,411.2279968261719)"
            >
              <path
                fill="rgb(253,199,201)"
                fillOpacity="1"
                d=" M68.63300323486328,106.27200317382812 C68.63300323486328,106.27200317382812 11.689000129699707,46.77199935913086 11.689000129699707,46.77199935913086 C11.689000129699707,46.77199935913086 12.010000228881836,46.94900131225586 12.010000228881836,46.94900131225586 C12.010000228881836,46.94900131225586 67.21499633789062,-41.43199920654297 67.21499633789062,-41.43199920654297 C67.21499633789062,-41.43199920654297 90.7040023803711,-77.1500015258789 55.93000030517578,-98.87000274658203 C21.1560001373291,-120.59100341796875 0.04600000008940697,-84.90499877929688 0.04600000008940697,-84.90499877929688 C0.04600000008940697,-84.90499877929688 -56.32400131225586,3.494999885559082 -56.32400131225586,3.494999885559082 C-56.32400131225586,3.494999885559082 -90.7030029296875,56.43899917602539 -71.15399932861328,78.06900024414062 C-32.7239990234375,120.59100341796875 44.284000396728516,204.73800659179688 44.284000396728516,204.73800659179688"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(0.9999179244041443,-0.012811299413442612,0.012811299413442612,0.9999179244041443,-9.68463134765625,9.94561767578125)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g
              opacity="1"
              transform="matrix(1,0,0,1,325.197998046875,389.6629943847656)"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M43.09000015258789,98.37799835205078 C43.09000015258789,98.37799835205078 13.010000228881836,69.16100311279297 13.010000228881836,69.16100311279297 C13.010000228881836,69.16100311279297 68.21499633789062,-19.22100067138672 68.21499633789062,-19.22100067138672 C68.21499633789062,-19.22100067138672 91.7040023803711,-54.9379997253418 56.93000030517578,-76.65899658203125 C22.1560001373291,-98.37899780273438 1.0449999570846558,-62.69300079345703 1.0449999570846558,-62.69300079345703 C1.0449999570846558,-62.69300079345703 -55.32400131225586,25.70599937438965 -55.32400131225586,25.70599937438965 C-55.32400131225586,25.70599937438965 -91.7040023803711,72.6510009765625 -72.15599822998047,94.28099822998047 C-72.15599822998047,94.28099822998047 46.801998138427734,234.33700561523438 46.801998138427734,234.33700561523438"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(0.9999179244041443,-0.012811299413442612,0.012811299413442612,0.9999179244041443,-9.68463134765625,9.94561767578125)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g
              opacity="1"
              transform="matrix(1,0,0,1,436.9289855957031,449.0469970703125)"
            >
              <path
                fill="rgb(0,0,0)"
                fillOpacity="1"
                d=" M13.062000274658203,-3.4100000858306885 C14.944999694824219,3.803999900817871 10.62399959564209,11.178999900817871 3.4100000858306885,13.062000274658203 C-3.803999900817871,14.944999694824219 -11.178000450134277,10.62399959564209 -13.062000274658203,3.4100000858306885 C-14.944999694824219,-3.803999900817871 -10.625,-11.178999900817871 -3.4100000858306885,-13.062000274658203 C3.803999900817871,-14.946000099182129 11.178999900817871,-10.62399959564209 13.062000274658203,-3.4100000858306885z"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(0.9999179244041443,-0.012811299413442612,0.012811299413442612,0.9999179244041443,-9.68463134765625,9.94561767578125)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g
              opacity="1"
              transform="matrix(1,0,0,1,591.7410278320312,408.6319885253906)"
            >
              <path
                fill="rgb(0,0,0)"
                fillOpacity="1"
                d=" M13.062000274658203,-3.4100000858306885 C14.946000099182129,3.803999900817871 10.62399959564209,11.178999900817871 3.4100000858306885,13.062000274658203 C-3.803999900817871,14.944999694824219 -11.178999900817871,10.62399959564209 -13.062000274658203,3.4100000858306885 C-14.946000099182129,-3.803999900817871 -10.625,-11.178999900817871 -3.4110000133514404,-13.062000274658203 C3.803999900817871,-14.946000099182129 11.178999900817871,-10.62399959564209 13.062000274658203,-3.4100000858306885z"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(0.9999179244041443,-0.012811299413442612,0.012811299413442612,0.9999179244041443,-9.68463134765625,9.94561767578125)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g
              opacity="1"
              transform="matrix(1,0,0,1,510.28900146484375,434.85198974609375)"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M-48.029998779296875,31.625 C-48.029998779296875,31.625 2.441999912261963,-31.625 48.02899932861328,12.21399974822998"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(0.9999179244041443,-0.012811299413442612,0.012811299413442612,0.9999179244041443,-9.68463134765625,9.94561767578125)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g
              opacity="1"
              transform="matrix(1,0,0,1,597.1630249023438,381.8949890136719)"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M-19.711000442504883,-6.2230000495910645 C-19.711000442504883,-6.2230000495910645 -6.247000217437744,5.764999866485596 19.711000442504883,6.2230000495910645"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(0.9999179244041443,-0.012811299413442612,0.012811299413442612,0.9999179244041443,-9.68463134765625,9.94561767578125)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g
              opacity="1"
              transform="matrix(1,0,0,1,430.0899963378906,420.59100341796875)"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M-15.420999526977539,9.133999824523926 C-15.420999526977539,9.133999824523926 12.522000312805176,-0.6729999780654907 15.420999526977539,-9.133999824523926"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(0.9998769164085388,0.015690211206674576,-0.015690211206674576,0.9998769164085388,-4.053131103515625,-34.4454345703125)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g opacity="1" transform="matrix(1,0,0,1,434,601)">
              <path
                fill="rgb(253,199,201)"
                fillOpacity="1"
                d=" M-221,-88 C-221,-88 -143,120 -143,120 C-143,120 -76,105 -76,105 C-76,105 -15,140 -15,140 C-15,140 59,114 59,114 C59,114 144,150 144,150 C144,150 217,127 217,127 C217,127 221,-150 221,-150 C221,-150 136,-118 136,-118 C136,-118 83,-135 83,-135 C83,-135 -24,-88 -24,-88 C-24,-88 -102,-132 -102,-132 C-102,-132 -221,-88 -221,-88z"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(0.9998769164085388,0.015690211206674576,-0.015690211206674576,0.9998769164085388,1.749542236328125,-26.286376953125)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g opacity="1" transform="matrix(1,0,0,1,422,598.5)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M-217,-95.5 C-217,-95.5 -143,112.5 -143,112.5 C-143,112.5 -76,97.5 -76,97.5 C-76,97.5 -15,132.5 -15,132.5 C-15,132.5 59,106.5 59,106.5 C59,106.5 144,142.5 144,142.5 C144,142.5 217,119.5 217,119.5 C217,119.5 217,-142.5 217,-142.5 C217,-142.5 144,-116.5 144,-116.5 C144,-116.5 83,-142.5 83,-142.5 C83,-142.5 -24,-95.5 -24,-95.5 C-24,-95.5 -102,-139.5 -102,-139.5 C-102,-139.5 -217,-95.5 -217,-95.5z"
              ></path>
            </g>
            <g
              opacity="1"
              transform="matrix(1,0,0,1,331.9159851074219,573.7109985351562)"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M-14.085000038146973,-99.28900146484375 C-14.085000038146973,-99.28900146484375 14.083999633789062,99.28900146484375 14.083999633789062,99.28900146484375"
              ></path>
            </g>
            <g opacity="1" transform="matrix(1,0,0,1,0,0)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M405,513 C405,513 405,705 405,705"
              ></path>
            </g>
            <g
              opacity="1"
              transform="matrix(1,0,0,1,497.2049865722656,580.7109985351562)"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M8.204999923706055,-106.28900146484375 C8.204999923706055,-106.28900146484375 -8.204999923706055,106.28900146484375 -8.204999923706055,106.28900146484375"
              ></path>
            </g>
            <g opacity="1" transform="matrix(1,0,0,1,0,0)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M566,722.9630126953125 C566,722.9630126953125 566,500 566,500"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(0.9998769164085388,0.015690211206674576,-0.015690211206674576,0.9998769164085388,1.749542236328125,-26.286376953125)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g
              opacity="1"
              transform="matrix(1,0,0,1,682.3510131835938,602.2570190429688)"
            >
              <path
                fill="rgb(253,199,201)"
                fillOpacity="1"
                d=" M105.86100006103516,5.521999835968018 C105.86100006103516,5.521999835968018 27.823999404907227,22.138999938964844 27.823999404907227,22.138999938964844 C27.823999404907227,22.138999938964844 -14.486000061035156,-73.08999633789062 -14.486000061035156,-73.08999633789062 C-14.486000061035156,-73.08999633789062 -30.92300033569336,-112.552001953125 -68.39199829101562,-95.90599822998047 C-105.86100006103516,-79.25900268554688 -88.11699676513672,-41.784000396728516 -88.11699676513672,-41.784000396728516 C-88.11699676513672,-41.784000396728516 -46.44599914550781,54.41899871826172 -46.44599914550781,54.41899871826172 C-46.44599914550781,54.41899871826172 -21.833999633789062,112.552001953125 7.033999919891357,108.46900177001953 C63.784000396728516,100.44200134277344 100.1510009765625,90.14099884033203 100.1510009765625,90.14099884033203"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(0.9998769164085388,0.015690211206674576,-0.015690211206674576,0.9998769164085388,1.749542236328125,-26.286376953125)"
            opacity="1"
            style={{
              display: "block",
            }}
          >
            <g
              opacity="1"
              transform="matrix(1,0,0,1,685.4669799804688,599.4000244140625)"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="rgb(0,0,0)"
                strokeOpacity="1"
                strokeWidth="10"
                d=" M83.447998046875,10.097000122070312 C83.447998046875,10.097000122070312 27.420000076293945,22.141000747680664 27.420000076293945,22.141000747680664 C27.420000076293945,22.141000747680664 -14.888999938964844,-73.08999633789062 -14.888999938964844,-73.08999633789062 C-14.888999938964844,-73.08999633789062 -31.32699966430664,-112.552001953125 -68.79499816894531,-95.90499877929688 C-106.26399993896484,-79.25800323486328 -88.52100372314453,-41.78499984741211 -88.52100372314453,-41.78499984741211 C-88.52100372314453,-41.78499984741211 -46.849998474121094,54.41999816894531 -46.849998474121094,54.41999816894531 C-46.849998474121094,54.41999816894531 -22.238000869750977,112.5510025024414 6.630000114440918,108.46800231933594 C63.380001068115234,100.44200134277344 106.26399993896484,89.14800262451172 106.26399993896484,89.14800262451172"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
