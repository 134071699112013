import React, { FC } from "react";
import { IconProps } from "../types";

export const Envelope: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      color={fill}
      xmlns="http://www.w3.org/2000/svg"
      width="1024"
      viewBox="0 0 768 768"
      height="1024"
      version="1.0"
    >
      <path
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
        d="M140.25 170.719h487.5a30.6 30.6 0 0 1 8.844 1.312q1.434.434 2.816 1.008 1.384.575 2.703 1.277 1.32.706 2.563 1.54 1.248.826 2.402 1.78 1.16.949 2.215 2.009a29 29 0 0 1 2.008 2.214q.954 1.154 1.781 2.403a30 30 0 0 1 2.816 5.265q.575 1.382 1.008 2.817a30.3 30.3 0 0 1 1.164 5.855q.148 1.494.149 2.989v365.624a30.6 30.6 0 0 1-1.313 8.844q-.433 1.435-1.008 2.817a30 30 0 0 1-2.816 5.266 28 28 0 0 1-1.781 2.402q-.949 1.16-2.008 2.214a29 29 0 0 1-2.215 2.008q-1.154.955-2.402 1.782a30 30 0 0 1-5.266 2.816q-1.383.575-2.816 1.008a30.3 30.3 0 0 1-5.855 1.164q-1.495.147-2.989.148h-487.5a30.6 30.6 0 0 1-8.844-1.312 30 30 0 0 1-2.816-1.008 30 30 0 0 1-5.266-2.816 28 28 0 0 1-2.402-1.782 29 29 0 0 1-2.215-2.008 29 29 0 0 1-2.008-2.214q-.954-1.154-1.781-2.403a30 30 0 0 1-2.816-5.265 30 30 0 0 1-1.008-2.817 30.3 30.3 0 0 1-1.164-5.855 31 31 0 0 1-.149-2.989V201.188a30.6 30.6 0 0 1 1.313-8.844q.434-1.435 1.008-2.817a30 30 0 0 1 2.816-5.265 28 28 0 0 1 1.781-2.403q.949-1.16 2.008-2.214a29 29 0 0 1 2.215-2.008q1.154-.955 2.402-1.782a30 30 0 0 1 5.266-2.816q1.383-.574 2.816-1.008a30.3 30.3 0 0 1 5.855-1.164 31 31 0 0 1 2.989-.148m0 0"
        stroke="#d50f16"
        strokeWidth="65.813"
      />
      <path
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
        d="M627.75 185.953 384 414.47 140.25 185.953"
        stroke="#d50f16"
        strokeWidth="65.813"
      />
    </svg>
  );
};
