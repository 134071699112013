"use client";

import React from "react";
import googleScript from "@helpers/thirdpartyplugins/googletagmanager-script";
import { IS_PRODUCTION } from "@constants";

export const useAppEffect = () => {
  React.useEffect(() => {
    /* The code is calling three functions (`googleScript`, `metaScript`, and `microsoftClarityScript`)
    to retrieve scripts for Google Tag Manager, Meta Pixel Manager, and Microsoft Clarity
    respectively. These scripts are then assigned to the variables `google`, `meta`, and
    `microsoft`. */
    const google = googleScript();

    /* The code is appending the `google`, `meta`, and `microsoft` scripts to the `<head>` element of
    the HTML document. This is done by calling the `appendChild()` method on the `document.head`
    object and passing in the respective script elements as arguments. By appending these scripts to
    the `<head>` element, they will be loaded and executed when the HTML document is rendered in the
    browser. */

    if (IS_PRODUCTION) {
      document.head.appendChild(google);
    }

    return () => {
      // Clean up if needed
      if (document.head.contains(google)) {
        document.head.removeChild(google);
      }
    };
  }, []);
};
