import { ProductType } from "@graphql/types";

export type SearchType = "guides" | "tours" | "online-tours" | "transportation";

export const getProductType = (type: string): SearchType => {
  if (type === "car" || type === ProductType.Car) {
    return "transportation";
  }

  if (type === "online" || type === ProductType.Online) {
    return "online-tours";
  }

  return "tours";
};

export interface Params {
  country?: string | null;
  city?: string | null;
  q?: string | null;
  t?: SearchType | null;
  category?: string;
  duration?: string;
  language?: string;
  date?: string;
  adults?: number;
  children?: number;
  infants?: number;
  orderBy?: string;
  page?: number;
}

export const searchURL = (params: Params) => {
  const searchParams = new URLSearchParams();

  const { country, city, q, t, ...rest } = params;

  const paramsArray = Object.entries(rest) as [string, string][];
  const uniqueParamsArray = Array.from(new Set(paramsArray));

  ///////////// keep same order always
  if (country) {
    searchParams.set("country", country);
  }
  if (city) {
    searchParams.set("city", city);
  }
  if (q) {
    searchParams.set("q", q);
  }
  if (t) {
    searchParams.set("t", t);
  }
  /////////////

  uniqueParamsArray
    .sort((a, b) => a[0].localeCompare(b[0]))
    .forEach((param) => {
      if (param[1]) {
        searchParams.set(param[0], param[1]);
      }
    });

  if (searchParams.get("page") === "1") {
    searchParams.delete("page");
  }

  const url = `/s?${searchParams.toString()}`;
  return url;
};
