import Icon, { IconType } from "@components/ui/Icon";
import { t } from "@lingui/macro";
import classNames from "classnames";
import React from "react";
import { FC } from "react";
import { twMerge } from "tailwind-merge";

export enum CategoryTypes {
  guides = "guides",
  tours = "tours",
  transportation = "transportation",
  "online-tours" = "online-tours",
}

interface Props {
  category: CategoryTypes;
  selected?: boolean;
  actived: boolean;
  className?: string;
}

interface Categories {
  [key: string]: { icon: IconType; textDesktop: string; textMobile: string };
}

const Category: FC<Props> = ({
  category,
  selected = false,
  actived,
  className = "",
  ...props
}) => {
  const categories: Categories = {
    guides: {
      icon: "user",
      textDesktop: t({
        id: "category.button.tour-guides",
        message: "Tour Guides",
      }),
      textMobile: t({ id: "category.button.guides", message: "Guides" }),
    },

    tours: {
      icon: "flag",
      textDesktop: t({
        id: "category.button.private-tours",
        message: "Private Tours",
      }),
      textMobile: t({ id: "category.button.tours", message: "Tours" }),
    },

    "online-tours": {
      icon: "globe",
      textDesktop: t({
        id: "category.button.virtual-tours",
        message: "Virtual Tours",
      }),
      textMobile: t({
        id: "category.button.virtual",
        message: "Virtual",
      }),
    },

    transportation: {
      icon: "car",
      textDesktop: t({
        id: "category.button.private-car",
        message: "Private Car Tours",
      }),
      textMobile: t({
        id: "category.button.cars",
        message: "Cars",
      }),
    },
  };

  const content = categories[category];

  const wrapperClassName = twMerge(
    classNames(
      "flex cursor-pointer items-center font-semibold flex-col md:flex-row border shadow-md md:shadow-none rounded-lg md:rounded-full px-4 py-2",
      {
        "border px-4 py-2 bg-secondary text-white": selected,
        "opacity-30": !actived,
      }
    ),
    className
  );

  return (
    <div {...props} className={wrapperClassName}>
      <span>
        <Icon
          icon={content.icon}
          size="sm"
          className={`mr-0 md:mr-3 w-4 h-4 xxs:w-6 xxs:h-6 ${
            selected ? "text-white" : "text-secondary"
          }`}
        />
      </span>
      <span className="truncate md:w-full text-xxs xxs:text-xs xs:text-base md:text-base text-left">
        <span className="hidden md:flex">{content.textDesktop}</span>
        <span className="flex md:hidden">{content.textMobile}</span>
      </span>
    </div>
  );
};

export default Category;
