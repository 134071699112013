import Cookies from "universal-cookie";

interface CookieProps {
  get(key: string): string;
  set(key: string, value?: string | null): void;
  remove(key: string): void;
}

const cookies = (): CookieProps => {
  const OneDayInSeconds = 86400;
  const MAX_AGE_FOR_COOKIES = 30 * OneDayInSeconds;

  const cookies = new Cookies();

  //1 month of not using the app will delete the cookie
  return {
    get: (key) => {
      const value = cookies.get(key);
      if (value) {
        cookies.set(key, value, {
          path: "/",
          maxAge: MAX_AGE_FOR_COOKIES,
        });
      }
      return value;
    },
    set: (key, value) => {
      cookies.set(key, value, {
        path: "/",
        maxAge: MAX_AGE_FOR_COOKIES,
      });
    },
    remove: (key) => cookies.remove(key, { path: "/" }),
  };
};

export default cookies;
