import React, { FC } from "react";
import { IconProps } from "../types";

export const Brush: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      width="1024"
      zoomAndPan="magnify"
      viewBox="0 0 768 767.999994"
      height="1024"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="72d905d5c0">
          <path
            d="M 76.800781 227 L 480 227 L 480 630.574219 L 76.800781 630.574219 Z M 76.800781 227 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="484816bbb2">
          <path
            d="M 294 144 L 691.050781 144 L 691.050781 414 L 294 414 Z M 294 144 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#72d905d5c0)">
        <path
          fill={fill}
          d="M 95.835938 611.59375 C 123.761719 639.519531 169.960938 636.246094 193.632812 604.609375 L 307.523438 452.8125 L 341.015625 486.304688 C 366.324219 511.667969 407.394531 511.667969 432.757812 486.304688 L 479.503906 439.558594 L 267.816406 227.871094 L 221.070312 274.617188 C 195.761719 299.980469 195.761719 341.050781 221.070312 366.359375 L 254.5625 399.851562 L 102.761719 513.738281 C 71.183594 537.410156 67.855469 583.609375 95.835938 611.539062 L 95.835938 611.59375 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#484816bbb2)">
        <path
          fill={fill}
          d="M 440.613281 155.492188 C 400.632812 135.527344 352.304688 143.382812 320.722656 174.960938 L 294.269531 201.417969 L 505.957031 413.105469 L 691.191406 227.871094 C 621.589844 227.871094 552.921875 211.671875 490.683594 180.527344 L 440.613281 155.492188 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
