import React, { FC } from "react";
import { Trans } from "@lingui/macro";
import { IconProps } from "../types";

export const StarOutline: FC<IconProps> = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <title id="GWGLogoTitle">
        <Trans id="common.svg.title">GoWithGuide</Trans>
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path d="M22.527 7.75H15.0526L12.7812 0.975781C12.6695 0.645703 12.3595 0.4375 12.0089 0.4375C11.6583 0.4375 11.3483 0.645703 11.2366 0.975781L8.96524 7.75H1.43995C0.992801 7.75 0.626953 8.11562 0.626953 8.5625C0.626953 8.6082 0.632034 8.65898 0.642197 8.69961C0.652359 8.87734 0.733659 9.07539 0.982639 9.27344L7.12584 13.6L4.76815 20.4504C4.65128 20.7805 4.76815 21.1512 5.04762 21.3645C5.19497 21.4711 5.33217 21.5625 5.50493 21.5625C5.67261 21.5625 5.87078 21.4762 6.01305 21.3797L12.0089 17.109L18.0047 21.3797C18.147 21.4812 18.3452 21.5625 18.5129 21.5625C18.6856 21.5625 18.8228 21.4762 18.9651 21.3645C19.2496 21.1512 19.3614 20.7855 19.2446 20.4504L16.8869 13.6L22.9793 9.23281L23.1266 9.10586C23.2587 8.96367 23.3908 8.7707 23.3908 8.5625C23.3908 8.11562 22.9742 7.75 22.527 7.75ZM16.0637 12.4473C15.5556 12.8129 15.3422 13.473 15.5454 14.0621L17.0749 18.5156C17.1409 18.7035 16.9275 18.8609 16.7649 18.7492L12.8321 15.9461C12.5831 15.7684 12.2934 15.682 12.0038 15.682C11.7142 15.682 11.4246 15.7684 11.1807 15.9461L7.24779 18.7441C7.08519 18.8609 6.87178 18.6984 6.93783 18.5105L8.46728 14.057C8.67053 13.4578 8.45712 12.7977 7.94392 12.432L3.82812 9.53242C3.66553 9.4207 3.74683 9.16172 3.94499 9.16172H8.96016C9.56991 9.16172 10.1136 8.7707 10.3067 8.1918L11.8107 3.71289C11.8717 3.53008 12.1359 3.53008 12.1969 3.71289L13.7009 8.1918C13.894 8.7707 14.4377 9.16172 15.0475 9.16172H19.9915C20.1897 9.16172 20.271 9.41562 20.1084 9.52734L16.0637 12.4473Z" />
    </svg>
  );
};
