import { MeContextQuery } from "@graphql/types";
import { IUser } from "./context/AuthContext";

const getUserFromQuery = (result: MeContextQuery): IUser => {
  const user =
    result.me.__typename === "Supplier"
      ? {
          __typename: result.me.__typename,
          id: result.me.id,
          displayName: result.me.displayName,
          firstName: result.me.firstName,
          isAdmin: result.me.isAdmin,
          isBlogger: result.me.isBlogger,
          isSupplier: result.me.isSupplier,
          picture: {
            url: result.me.picture?.url,
          },
          isSupplierApply: result.me.isSupplierApply,
          guideApplyStep: result.me.guideApplyStep,
          payoutMethodStatus: result.me.payoutMethodStatus,
          payoutMethodType: result.me.payoutMethodType,
          stats: result.me.stats,
        }
      : {
          __typename: result.me.__typename,
          id: result.me.id,
          displayName: result.me.displayName,
          firstName: result.me.firstName,
          isAdmin: result.me.isAdmin,
          isBlogger: result.me.isBlogger,
          isSupplier: result.me.isSupplier,
          picture: {
            url: result.me.picture?.url,
          },
        };
  return user;
};

export default getUserFromQuery;
