import { COOKIE_LOCALE, DEFAULT_LOCALE, LOCALES } from "@constants";
import { useEffect, useState } from "react";
import cookies from "./cookies";

export const getLocale = () => {
  return cookies().get(COOKIE_LOCALE) || DEFAULT_LOCALE;
};

export const isValidLocale = (locale: string) => {
  return Object.keys(LOCALES).includes(locale);
};

export const useLocale = () => {
  const [locale, setLocale] = useState<string>(() => getLocale());

  const changeLocale = (newLocale: string) => {
    if (!isValidLocale(newLocale)) {
      return;
    }

    cookies().set(COOKIE_LOCALE, newLocale);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("lang");
    const queryParams = searchParams.toString();
    const url = `${window.location.pathname}${
      queryParams ? `?${queryParams}` : ``
    }`;

    window.location.href = url;
  };

  useEffect(() => {
    setLocale(getLocale());
  }, []);

  return { locale, setLocale: changeLocale };
};
