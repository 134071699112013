import React, { useEffect } from "react";
import type { AppProps } from "next/app";
import { I18nProvider } from "@lingui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import { useIsClient } from "@helpers/hooks/useIsClient";
import { useAppEffect } from "@helpers/hooks/useAppEffect";
import { AuthProvider } from "@helpers/context/AuthContext";
import { useLocaleEffect } from "@helpers/hooks/useLocaleEffect";
import { useBrowserSupport } from "@helpers/hooks/useBrowserSupport";
import { bugsnag } from "@helpers/bugsnag";
import { i18n } from "@lingui/core";

// bugsnag
const ErrorBoundary = bugsnag
  .getPlugin("react")
  ?.createErrorBoundary(React) as BugsnagErrorBoundary;

import { BugsnagErrorBoundary } from "@bugsnag/plugin-react";
import { useRouter } from "next/router";
import {
  DEFAULT_LOCALE,
  ROUTE_SIGNBOARD_PRINT,
  ROUTE_SUPPLIER_APPLY,
} from "@constants";
import { NextPage } from "next";
import type { ReactElement, ReactNode } from "react";
import { useLocale } from "@helpers/locale";
import dynamic from "next/dynamic";

const DynamicAuthLayout = dynamic(
  () => import("@components/layouts/AuthLayout")
);
const DynamicAuthSupplierLayout = dynamic(
  () => import("@components/layouts/AuthSupplierLayout")
);
const DynamicDefaultLayout = dynamic(
  () => import("@components/layouts/components/DefaultLayout")
);
const DynamicSupplierApplyAlert = dynamic(
  () => import("@components/common/SupplierApplyAlert/SupplierApplyAlert")
);
const DynamicSupplierUpdateCalendarAlert = dynamic(
  () => import("@components/common/SupplierUpdateCalendarAlert")
);
const DynamicPayoutMissingNotification = dynamic(
  () => import("@components/common/PayoutMissingNotification")
);
const DynamicDefaultHead = dynamic(
  () => import("@components/common/DefaultHead")
);

// styles
import "../styles/global.css";
import "prismjs/themes/prism-tomorrow.css";
import "../styles/no-tailwind.css";
import { SearchProvider } from "@helpers/context/SearchContext";

const REACT_QUERY_DEFAULTS = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchInterval: undefined,
};

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const { locale } = useLocale();

  useLocaleEffect();
  useAppEffect();
  useBrowserSupport();
  const isClient = useIsClient();

  const getLayout =
    Component.getLayout ??
    ((page) => {
      if (router.pathname.startsWith("/auth/supplier")) {
        // the apply flow pages cant use authsupplier beacause the supplier column gonna be changed just after the apply flow
        if (router.pathname.startsWith("/auth/supplier/apply")) {
          return <DynamicAuthLayout>{page}</DynamicAuthLayout>;
        }
        return <DynamicAuthSupplierLayout>{page}</DynamicAuthSupplierLayout>;
      }

      return <DynamicDefaultLayout>{page}</DynamicDefaultLayout>;
    });

  const queryClient = new QueryClient({
    defaultOptions: { queries: { ...REACT_QUERY_DEFAULTS } },
  });

  const changeLang = (lang: string) => {
    document.documentElement.setAttribute("lang", lang);
  };

  useEffect(() => {
    changeLang(locale || DEFAULT_LOCALE);
  }, [locale]);

  useEffect(() => {
    // disable prefetch
    if (process.env.NEXT_PUBLIC_DISABLE_PREFETCH === "true") {
      const prefetch = router.prefetch;
      router.prefetch = async () => {
        // prefetch disable
      };
      return () => {
        router.prefetch = prefetch;
      };
    } else {
      return () => {
        // empty return to prevent eslint error
      };
    }
  }, [router]);

  // For supplier landing page, don't show loading page from AuthProvider
  if (
    router.asPath === "/supplier" ||
    router.asPath.startsWith(ROUTE_SIGNBOARD_PRINT)
  ) {
    return (
      <ErrorBoundary>
        <SearchProvider>
          <AuthProvider>
            <I18nProvider i18n={i18n}>
              <QueryClientProvider client={queryClient}>
                {router.asPath != "/supplier" && <DynamicSupplierApplyAlert />}
                <DynamicSupplierUpdateCalendarAlert />
                <Component {...pageProps} />

                <ToastContainer hideProgressBar />
                {isClient && <ReactQueryDevtools initialIsOpen={false} />}
              </QueryClientProvider>
            </I18nProvider>
          </AuthProvider>
        </SearchProvider>
      </ErrorBoundary>
    );
  }

  if (router.asPath.startsWith(ROUTE_SUPPLIER_APPLY)) {
    return (
      <ErrorBoundary>
        <SearchProvider>
          <AuthProvider>
            <I18nProvider i18n={i18n}>
              <QueryClientProvider client={queryClient}>
                <Component {...pageProps} />
                <ToastContainer hideProgressBar />
                {isClient && <ReactQueryDevtools initialIsOpen={false} />}
              </QueryClientProvider>
            </I18nProvider>
          </AuthProvider>
        </SearchProvider>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <SearchProvider>
        <AuthProvider>
          <I18nProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
              <DynamicDefaultHead />
              <DynamicPayoutMissingNotification />

              {getLayout(<Component {...pageProps} />)}

              <ToastContainer hideProgressBar />
              {isClient && <ReactQueryDevtools initialIsOpen={false} />}
            </QueryClientProvider>
          </I18nProvider>
        </AuthProvider>
      </SearchProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
