import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { COOKIE_TOKEN, COOKIE_USER_ME } from "@constants";
import { refreshGraphQl } from "@helpers/graphql";
import cookies from "@helpers/cookies";
import Router, { useRouter } from "next/router";
import {
  Maybe,
  SupplierContextFragment,
  UserContextFragment,
} from "@graphql/types";
import getUserFromQuery from "@helpers/getUserFromQuery";

type Auth = UserContextFragment | SupplierContextFragment;

export type IUser = {
  id: number;
  firstName: string;
  displayName: string;
  isBlogger: boolean;
  isSupplier: boolean;
  isAdmin: boolean;
  payoutMethodStatus?: string;
  payoutMethodType?: string;
  isSupplierApply?: Maybe<boolean>;
  guideApplyStep?: Maybe<string>;
  picture: {
    url?: string;
  };
  stats?: {
    bookingsCount: number;
  };
};

type AuthContextState = {
  auth?: Auth;
  isLoading: boolean;
  isLoggingOut: boolean;
  logout: (redirectTo?: string, windowRedirect?: boolean) => void;
  login: (token: string, redirectTo?: string) => Promise<void>;
  localLogin: (token: string) => Promise<void>;
  setAuth: Dispatch<SetStateAction<Auth | undefined>>;
  syncAuthOldApp: (dparamsString: string, isAutoLogin?: boolean) => void;
  syncAuthNewApp: (token?: string, redirectTo?: string) => void;
  getToken: () => string | null;
  refreshNotificationBadge: boolean;
  setRefreshNotificationBadge: (value: boolean) => void;
};

export const AuthContext = createContext<AuthContextState>(
  {} as AuthContextState
);

export const AuthProvider: FC = ({ children, ...props }) => {
  const router = useRouter();
  const [auth, setAuth] = useState<Auth | undefined>();
  const [isLoading, setIsLoading] = useState(
    !!cookies().get(COOKIE_USER_ME) ||
      !!cookies().get(COOKIE_TOKEN) ||
      !!router.query.token
  );
  const [refreshNotificationBadge, setRefreshNotificationBadge] = useState(
    false
  );

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const logout = (redirectTo?: string, windowRedirect = false) => {
    setIsLoggingOut(true);
    setAuth(undefined);

    cookies().remove(COOKIE_TOKEN);
    cookies().remove(COOKIE_USER_ME);

    const params = new URLSearchParams();

    const path = Router.pathname as string;

    if (
      windowRedirect &&
      ["/support", "/auth"].filter((item) => path.startsWith(item))
    ) {
      window.location.href = "/login";
    }

    // dont redirect
    if (!path.includes("/login?unauthenticated")) {
      return;
    }

    // After logout, redirect to root in case of auth based url's
    if (path.includes("auth")) {
      params.append("redirectTo", "/");
    }

    if (!path.includes("auth") && redirectTo) {
      params.append("redirectTo", encodeURI(redirectTo));
    }

    // syncro logout
    syncAuthNewApp(redirectTo);
    //syncAuthOldApp(params.toString());
  };

  const login = async (token: string, redirectTo?: string) => {
    localLogin(token).then(() => {
      const params = new URLSearchParams();
      params.append("token", token);

      if (redirectTo) {
        params.append("redirectTo", encodeURI(redirectTo));
      }

      syncAuthNewApp(redirectTo);
    });
  };

  const localLogin = async (token: string) => {
    const result = await refreshGraphQl({ authorization: token }).meContext();

    const user = getUserFromQuery(result);

    cookies().set(COOKIE_TOKEN, token);
    cookies().set(COOKIE_USER_ME, JSON.stringify(user));

    setAuth(result.me);
  };

  const syncAuthOldApp = (paramsString: string, isAutoLogin?: boolean) => {
    const url = `/${isAutoLogin ? "autologin" : "autologout"}?${paramsString}`;
    window.location.href = url;
  };

  const value = {
    auth,
    isLoading,
    isLoggingOut,
    setAuth,
    logout,
    login,
    localLogin,
    syncAuthOldApp,
    syncAuthNewApp,
    getToken,
    refreshNotificationBadge,
    setRefreshNotificationBadge,
  };

  const loadUser = async () => {
    if (router.query.token) {
      const redirectTo = router.query.redirect || "";
      let path = router.asPath;
      if (path && path.includes("?")) {
        path = path.split("?")[0];
      }
      await localLogin(router.query.token as string).then(() => {
        syncAuthNewApp(String(redirectTo || path));
      });
      return;
    }

    const userContext = cookies().get(COOKIE_USER_ME);

    if (!userContext) {
      const token = getToken();
      if (token) {
        await refreshGraphQl({ Authorization: token })
          .meContext()
          .then((result) => {
            setIsLoading(false);
            setAuth(result.me);
            cookies().set(
              COOKIE_USER_ME,
              JSON.stringify(getUserFromQuery(result))
            );
          });
        return;
      }
    }

    if (userContext) {
      setAuth(JSON.parse(JSON.stringify(userContext)));
      const token = getToken();
      setIsLoading(false);
      // check updates in the users data

      if (token) {
        await refreshGraphQl({ Authorization: token })
          .meContext()
          .then((result) => {
            setAuth(result.me);
            cookies().set(
              COOKIE_USER_ME,
              JSON.stringify(getUserFromQuery(result))
            );
          });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadUser();
  }, [router.query.token, router.isReady]);

  return (
    <AuthContext.Provider value={value} {...props}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

const syncAuthNewApp = (redirectTo?: string) => {
  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";
  const url = `${origin}/${
    !!redirectTo && redirectTo !== "/" ? redirectTo : ""
  }`;

  window.location.href = redirectTo?.includes("http") ? redirectTo : url;
};

export const getToken = () => cookies().get(COOKIE_TOKEN) || null;
