import React, { FC } from "react";
import { IconProps } from "../types";

export const Talk: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      color={fill}
      xmlns="http://www.w3.org/2000/svg"
      width="1024"
      viewBox="0 0 768 768"
      height="1024"
      version="1.0"
    >
      <path
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
        d="m329.377 464.69-83.217 84.272c-11.637 11.745-17.428 17.618-22.408 18.05a13.53 13.53 0 0 1-11.393-4.681c-3.248-3.789-3.248-12.07-3.248-28.605v-42.218c0-14.803-12.15-25.52-26.792-27.684a81.19 81.19 0 0 1-68.576-68.55c-.866-5.845-.866-12.854-.866-26.792V242.777c0-45.465 0-68.197 8.85-85.572a81.2 81.2 0 0 1 35.478-35.479c17.374-8.849 40.107-8.849 85.572-8.849H443.04c45.465 0 68.197 0 85.571 8.85a81.2 81.2 0 0 1 35.48 35.478c8.848 17.374 8.848 40.107 8.848 85.572V356.44m0 297.687-58.888-40.946c-8.28-5.764-12.448-8.66-16.94-10.69a54 54 0 0 0-12.504-3.923c-4.87-.893-9.931-.893-20.026-.893h-48.604c-30.31 0-45.465 0-57.048-5.9a54.13 54.13 0 0 1-23.652-23.653c-5.9-11.582-5.9-26.737-5.9-57.047v-68.036c0-30.31 0-45.465 5.9-57.047a54.13 54.13 0 0 1 23.652-23.653c11.583-5.9 26.738-5.9 57.048-5.9h151.55c30.31 0 45.465 0 57.048 5.9a54.13 54.13 0 0 1 23.652 23.653c5.9 11.582 5.9 26.738 5.9 57.047v73.448c0 25.222 0 37.834-4.113 47.793a54.13 54.13 0 0 1-29.31 29.281c-9.931 4.114-22.542 4.114-47.765 4.114Zm0 0"
        stroke="#d50f16"
        strokeWidth="64.95"
      />
    </svg>
  );
};
