import React, { FC } from "react";
import { Trans } from "@lingui/macro";
import { IconProps } from "../types";

export const Ticket: FC<IconProps> = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <title id="GWGLogoTitle">
        <Trans id="common.svg.title">GoWithGuide</Trans>
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
        d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
      />
    </svg>
  );
};
