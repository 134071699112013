"use client";

import { COOKIE_LOCALE } from "@constants";
import cookies from "@helpers/cookies";
import { isValidLocale, useLocale } from "@helpers/locale";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { i18n } from "@lingui/core";
import { en, ja } from "make-plural/plurals";
import messagesEn from "../../locales/en/messages.js";
import messagesJA from "../../locales/ja/messages.js";

i18n.loadLocaleData("en", { plurals: en });
i18n.loadLocaleData("ja", { plurals: ja });

i18n.load({
  en: messagesEn.messages,
  ja: messagesJA.messages,
});

let isFirstRender = true;

export const useLocaleEffect = () => {
  const { query, isReady } = useRouter();
  const { locale } = useLocale();

  if (isFirstRender) {
    i18n.activate(locale);
  }

  useEffect(() => {
    const queryLocale = query.lang as string | undefined;

    if (!isReady) {
      return;
    }

    const newLocale = queryLocale || locale;

    if (newLocale && isValidLocale(newLocale)) {
      cookies().set(COOKIE_LOCALE, newLocale);
      i18n.activate(locale);
    }

    if (!isFirstRender) {
      i18n.activate(locale);
    }
    isFirstRender = false;
  }, [isReady]);
};
