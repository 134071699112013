import React, { FC } from "react";
import { IconProps } from "../types";

export const People: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      color={fill}
      xmlns="http://www.w3.org/2000/svg"
      width="1024"
      viewBox="0 0 768 768"
      height="1024"
      version="1.0"
    >
      <defs>
        <clipPath id="a">
          <path d="M66 316h636v384H66Zm0 0" />
        </clipPath>
      </defs>
      <path
        fill="#d50f16"
        d="M252.621 364.75c62.984 0 114.227-51.242 114.227-114.227 0-62.984-51.239-114.226-114.227-114.226-62.984 0-114.223 51.242-114.223 114.226S189.637 364.75 252.621 364.75Zm0-186.125c39.645 0 71.899 32.254 71.899 71.895 0 39.644-32.254 71.898-71.899 71.898-39.644 0-71.894-32.254-71.894-71.898 0-39.641 32.25-71.895 71.894-71.895ZM515.379 364.75c62.984 0 114.223-51.242 114.223-114.227 0-62.984-51.239-114.226-114.223-114.226s-114.227 51.242-114.227 114.226S452.395 364.75 515.38 364.75Zm0-186.125c39.644 0 71.894 32.254 71.894 71.895 0 39.644-32.253 71.898-71.894 71.898-39.645 0-71.899-32.254-71.899-71.898 0-39.641 32.254-71.895 71.899-71.895Zm0 0"
        stroke="#d50f16"
        strokeWidth="12"
      />
      <path
        fill="#d50f16"
        d="M383.996 447.578C285.16 324.468 84 394.25 84 553.352v57.187c0 11.688 9.477 21.164 21.164 21.164h557.672c11.687 0 21.164-9.476 21.164-21.164v-57.187c0-159.149-201.3-228.778-300.004-105.774m-5.09 141.793H126.328v-36.02c0-69.632 56.656-126.28 126.297-126.28 70.297 0 126.281 56.874 126.281 126.28Zm262.766 0H421.234c0-39.555 2.52-64.543-13.476-102.16 22.918-37.34 63.293-60.14 107.617-60.14 69.64 0 126.293 56.648 126.293 126.28v36.02Zm0 0"
      />
      <g clipPath="url(#a)">
        <path
          fill="none"
          d="M383.996 447.578C285.16 324.468 84 394.25 84 553.352v57.187c0 11.688 9.477 21.164 21.164 21.164h557.672c11.687 0 21.164-9.476 21.164-21.164v-57.187c0-159.149-201.3-228.778-300.004-105.774Zm-5.09 141.793H126.328v-36.02c0-69.632 56.656-126.28 126.297-126.28 70.297 0 126.281 56.874 126.281 126.28Zm262.766 0H421.234c0-39.555 2.52-64.543-13.476-102.16 22.918-37.34 63.293-60.14 107.617-60.14 69.64 0 126.293 56.648 126.293 126.28v36.02Zm0 0"
          stroke="#d50f16"
          strokeWidth="12"
        />
      </g>
    </svg>
  );
};
