"use client";

import Router from "next/router";
import React from "react";

export const useBrowserSupport = () => {
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isIE = /*@cc_on!@*/ false || !!(document as any).documentMode;

    if (isIE && Router.pathname != "browser-support") {
      Router.push("/browser-support");
    }
  }, []);
};
