import React, { FC } from "react";
import { Trans } from "@lingui/macro";
import { IconProps } from "../types";

export const Announce: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      width="800px"
      height="800px"
      viewBox="0 0 64 64"
      stroke="currentColor"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="GWGLogoTitle">
        <Trans id="common.svg.title">GoWithGuide</Trans>
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <g id="Layer_1" />

      <g id="Layer_2">
        <g>
          <path
            strokeWidth={2}
            fill={fill}
            d="M49.4,11.7L24.2,18H9.4v3h-6v14h1h5v3h1H13l2.7,14h0.8h6.5l-2.7-14h3.8l25.1,6.3V43v-8h3.3V21h-3.3V11.7z M9.4,33h-4V23h4    V33z M20.7,50h-3.3l-2.3-12h3.3L20.7,50z M23.4,36H20h-7.4h-1.3v-1V21v-1h12V36z M47.4,21L47.4,21l0,14h0v6.7l-22-5.5V19.8l22-5.5    V21z M50.6,23v10h-1.3V23H50.6z"
          />

          <rect height="2" width="4.8" x="54.9" y="27" />

          <rect
            height="2"
            transform="matrix(0.9126 -0.4088 0.4088 0.9126 -3.5859 25.19)"
            width="5"
            x="54.7"
            y="20"
          />

          <rect
            height="5"
            transform="matrix(0.4088 -0.9126 0.9126 0.4088 1.827 72.8513)"
            width="2"
            x="56.1"
            y="32.5"
          />
        </g>
      </g>
    </svg>
  );
};
