import React, { FC } from "react";
import { Trans } from "@lingui/macro";
import { IconProps } from "../types";

export const TemplateIcon: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
    >
      <title id="GWGLogoTitle">
        <Trans id="common.svg.title">GoWithGuide</Trans>
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        d="M16.125 0H1.875C0.839484 0 0 0.839438 0 1.875V16.125C0 17.1606 0.839484 18 1.875 18H16.125C17.1606 18 18 17.1606 18 16.125V1.875C18 0.839438 17.1606 0 16.125 0ZM11.25 14.25H3.75V12H11.25V14.25ZM14.25 10.125H3.75V7.875H14.25V10.125ZM14.25 6H3.75V3.75H14.25V6Z"
        fill={fill}
        strokeWidth={0.1}
      />
    </svg>
  );
};
