import React, { FC } from "react";
import { IconProps } from "../types";

export const FaqDocument: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.226 5.64844H6.91797V6.1276H15.226V5.64844Z" fill="black" />
      <path d="M15.226 7.40625H6.91797V7.88563H15.226V7.40625Z" fill="black" />
      <path d="M15.226 9.32422H6.91797V9.8036H15.226V9.32422Z" fill="black" />
      <path d="M15.226 11.0781H6.91797V11.5575H15.226V11.0781Z" fill="black" />
      <path d="M15.226 12.9961H6.91797V13.4755H15.226V12.9961Z" fill="black" />
      <path
        d="M16.9016 1.93359H5.39886C4.60598 1.93359 3.96094 2.57863 3.96094 3.37151V18.6283C3.96094 19.4212 4.60598 20.0662 5.39886 20.0662H14.665C16.6912 20.0662 18.3395 18.418 18.3395 16.3918V3.37151C18.3395 2.57841 17.6944 1.93359 16.9016 1.93359ZM4.91926 18.6283V3.37151C4.91926 3.10707 5.13464 2.89213 5.39886 2.89213H16.9016C17.1656 2.89213 17.3807 3.10707 17.3807 3.37151V15.7126H13.7864V19.1077H5.39886C5.13464 19.1077 4.91926 18.8925 4.91926 18.6283ZM14.7449 19.1035V16.6712H17.3664C17.2289 18.0119 16.1111 19.0632 14.7449 19.1035Z"
        fill="black"
      />
    </svg>
  );
};
