import React, { FC } from "react";
import { IconProps } from "../types";

export const VerifiedGlyph: FC<IconProps> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1024"
      viewBox="0 0 768 768"
      height="1024"
      version="1.0"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path d="M76.5 76.5h615v615h-615Zm0 0" />
        </clipPath>
        <clipPath id="b">
          <path d="M384 76.5C214.172 76.5 76.5 214.172 76.5 384S214.172 691.5 384 691.5 691.5 553.828 691.5 384 553.828 76.5 384 76.5m0 0" />
        </clipPath>
        <clipPath id="c">
          <path d="M225 193.852h318v380.25H225Zm0 0" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path fill="#0097ff" d="M76.5 76.5h615v615h-615Zm0 0" />
        </g>
      </g>
      <g clipPath="url(#c)">
        <path
          fill="#fff"
          d="m370.602 195.547-131.61 33.789c-7.762 1.992-13.195 9.008-13.195 17.062v142.66c0 68.563 50.992 150.387 148.144 183.419a31.26 31.26 0 0 0 20.11 0c97.152-33.032 148.144-114.856 148.144-183.418V246.414c0-8.055-5.433-15.086-13.195-17.078l-131.61-33.79a53.8 53.8 0 0 0-26.788 0m-38.918 176.52c-8.02-8.02-21.024-8.02-29.043 0-8.02 8.019-8.02 21.019 0 29.038l50.554 50.555c8.02 8.02 21.02 8.02 29.043 0l96.805-96.805c8.02-8.02 8.02-21.023 0-29.043-8.02-8.015-21.023-8.015-29.043 0l-82.285 82.286Zm0 0"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};
