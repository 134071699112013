import { GTM_ID } from "@constants";

/**
 * The `googleScript` function creates a script element that
 * loads the Google Tag Manager script with a specified GTM_ID.
 * @returns a dynamically created script element with the
 * Google Tag Manager (GTM) script code.
 */
const googleScript = () => {
  const script = document.createElement("script");
  script.appendChild(
    document.createTextNode(`
      (function(w,d,s,l,i){
        w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.defer=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GTM_ID}');
    `)
  );
  script.defer = true;

  return script;
};

export default googleScript;
