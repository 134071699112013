import React, { FC } from "react";
import { Trans } from "@lingui/macro";
import { IconProps } from "../types";

export const Bicycle: FC<IconProps> = ({ ...props }) => {
  return (
    <svg
      {...props}
      className={`${props.className} fill-current`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 496 496"
      stroke="currentColor"
    >
      <title id="GWGLogoTitle">
        <Trans id="common.svg.title">GoWithGuide</Trans>
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        strokeWidth={1}
        d="M381.172 204.683L355.016 125.5h21.993c8.837 0 16-7.163 16-16s-7.163-16-16-16H332.88c-10.886 0-18.613 10.668-15.192 21.019l15.681 47.47-11.343 14.511H185.438l-17.456-29h4.027c8.837 0 16-7.163 16-16s-7.163-16-16-16h-50c-8.837 0-16 7.163-16 16s7.163 16 16 16h8.625l84.268 140H197.27c-12.374-77.162-109.146-113.272-168.073-54.345-38.932 38.932-38.95 101.191 0 140.141 57.469 57.469 154.915 25.12 167.988-53.796h45.881c4.924 0 9.573-2.268 12.606-6.146l89.763-114.839 5.332 16.143c-31.91 16.589-53.758 49.961-53.758 88.343 0 54.864 44.636 99.5 99.5 99.5s99.5-44.636 99.5-99.5c0-60.626-54.22-107.741-114.837-98.318zm-84.158 3.817l-52.153 66.722-40.162-66.722zM99.267 370.483c-37.086 0-67.258-30.172-67.258-67.258s30.172-67.258 67.258-67.258c31.671 0 58.282 22.01 65.384 51.532h-65.71c-8.837 0-16 7.163-16 16s7.163 16 16 16h65.583c-7.297 29.248-33.781 50.984-65.257 50.984zm297.242.017c-37.22 0-67.5-30.28-67.5-67.5 0-24.198 12.806-45.452 31.989-57.37l20.561 62.243c2.222 6.726 8.475 10.985 15.189 10.985 10.86 0 18.622-10.649 15.195-21.022l-20.521-62.125c39.493-2.959 72.587 28.387 72.587 67.289 0 37.22-30.28 67.5-67.5 67.5z"
      ></path>
    </svg>
  );
};
