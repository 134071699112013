import React, { FC } from "react";
import { IconProps } from "../types";

export const Arrow: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      color={fill}
      xmlns="http://www.w3.org/2000/svg"
      width="1024"
      viewBox="0 0 768 768"
      height="1024"
      version="1.0"
    >
      <path
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
        d="m212.125 212.125 343.75 343.75m-343.75 0h343.75v-343.75"
        stroke="#d50f16"
        strokeWidth="74.25"
      />
    </svg>
  );
};
