import React, { FC } from "react";
import { IconProps } from "../types";

export const CheckBlueFilled: FC<IconProps> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1024"
      viewBox="0 0 768 768"
      height="1024"
      version="1.0"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path d="M76.5 76.5h615v615h-615Zm0 0" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path
          fill="#0297ff"
          d="M384 76.5C214.465 76.5 76.5 214.465 76.5 384S214.465 691.5 384 691.5 691.5 553.535 691.5 384 553.535 76.5 384 76.5m139.398 253.79L373.957 479.733c-5.742 5.946-13.531 8.817-21.117 8.817s-15.172-2.871-20.91-8.817l-87.328-87.328c-11.688-11.48-11.688-30.34 0-42.027 11.48-11.684 30.34-11.684 42.023 0l66.215 66.215 128.535-128.328c11.684-11.684 30.543-11.684 42.023 0 11.688 11.48 11.688 30.34 0 42.023m0 0"
        />
      </g>
    </svg>
  );
};
